import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { lazy, Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import store from './store';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = lazy(() => import('./components/Home'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const Pricing = lazy(() => import('./components/Pricing'));
const Login = lazy(() => import('./components/Login'));
const Register = lazy(() => import('./components/Register'));
const UploadFile = lazy(() => import('./components/UploadFile'));
const Question = lazy(() => import('./components/Question'));
const QuestionMobile = lazy(() => import('./components/QuestionMobile'));
const Payment = lazy(() => import('./Payment'));
const Terms = lazy(() => import('./terms'));
const Privacy = lazy(() => import('./privacy'));
const UserList = lazy(() => import('./components/UserList'));
const Settings = lazy(() => import('./components/Settings'));
//const SalesProposal = lazy(() => import('./components/SalesProposal'));

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
function App() {
    // console.log('bbb.js');
    return (
        <>
            <Suspense fallback={<div className="loading"><div className="loader-62"></div></div>}>
                <Provider store={store}>
                    <ToastContainer />
                    <GoogleOAuthProvider clientId={clientId || ''}>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/pricing" element={<Pricing />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/register" element={<Register />} />
                                <Route path="/upload" element={<UploadFile />} />
                                {/* <Route path="/questions" element={<Question />} /> */}
                                <Route path="/questions" element={isMobileDevice() ? <QuestionMobile /> : <Question />} />
                                {/* <Route path="/sales-proposals" element={<SalesProposal />} /> */}
                                <Route path="/payment" element={<Payment />} />
                                <Route path="/terms" element={<Terms />} />
                                <Route path="/privacy-policy" element={<Privacy />} />
                                <Route path="/settings" element={<Settings />} />
                                <Route path="/admin/users" element={<UserList />} />
                            </Routes>
                        </BrowserRouter>
                    </GoogleOAuthProvider>
                </Provider>
            </Suspense>
        </>
    );
}

function isMobileDevice() {
    console.log('Check mobile' + window.innerWidth)
    return window.innerWidth <= 768; // or another breakpoint if preferred
}
export default App;