// store.js
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
    // your reducers here
});

const store = createStore(
    rootReducer,
    applyMiddleware(thunk) // Apply middleware
);

export default store;
